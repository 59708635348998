.chapter-navigation {
    
    conf = theme.components.chapter-navigation;
    toggleSize = 10px;

    transition: all 0.2s;

    > .bar {

        height: rem( 50px );
        padding-left: theme.components.padding-box.large - rem( toggleSize );

        > .toggle {
            transition: transform 0.4s;
            padding: rem( toggleSize );
        }

        > .title {
            padding-left: rem( 15px - toggleSize );
            @extend .text-style.-navi;
        }
        
        &:hover {
            color: conf.action;
            cursor: pointer;
        }
    }

    > .children {
        display: none;
        color: conf.colors.elements;
    }

    > .border {
        border-left: 0 solid conf.colors.action;
        height: 100%;
        transition: all 0.2s;
    }

    &.-open {

        background-color: conf.colors.open;

        > .bar {
        
            color: conf.colors.action;
            border: none;


            & > .toggle {
                transform: rotate( 90deg );
            }

            & > .title {
                @extend .text-style.-medium;
            }
        }

        > .children {
            display: block;
        }

        > .border {
            border-width: 5px;
        }
    }


    .chapter-element {

        &.-current {

            > .title {
                background-color: conf.colors.current;
            }
        }

        > .title {
            padding: theme.components.padding-box.default theme.components.padding-box.medium theme.components.padding-box.default rem( 57px )
            min-height: 50px;
            display: flex;
            align-items: center;
            cursor: pointer;
            border-top: 1px solid #FFF;

            &:hover {
                color: conf.colors.action;    
            }
        }

    }
}