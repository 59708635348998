.Image {
  > .image {
    &.-hidden {
      display: none;
    }
  }
  > .caption {
    &.-hidden {
      display: none;
    }
  }
}
