.LocaleLink {
  text-transform: uppercase;

  &.-active {
    color: text-color;
    font-family: 'Helvetica Bold';
  }
}

.LocaleLink + .LocaleLink {
  margin-left: rem(10px);
}
