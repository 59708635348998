.Term {
  border-bottom: rem(1px) solid gray;

  > .header {
    padding-top: rem(25px);
    cursor: pointer;

    > a {
      color: inherit;
    }
    
    > .arrow {
      position: relative;
      top: 16px;
      height: 12px;
      right: 0px;
      width: 20px;
      display: inline-block;
      float: right;
      background-image: url('../images/arrow-up.svg');
      background-repeat: no-repeat;
    }
  }

  > .content > [view] {
    margin-bottom: rem(25px);
  }

  > .synonyms {
    font-style: italic;
    padding-bottom: rem( 30px );
  }

  &:not(.-open) {
    > .content {
      display: none;
    }
    > .header > .arrow {
      background-image url('../images/arrow-down.svg');
    }
  }
}
