/*
---
name: .chapter-layout
category: LernBuch/Chapter
tag:
 - lernbuch
 - chapter
 - layout
compile: true
---

This component controls the margins of the elements wtihin a chapter.
*/
.chapter-layout {
	
	> .element-layout + .element-layout {
		margin-top: theme.components.margin-box.small;
	}

	> .element-layout + [class*=' text-style -h'] {
		margin-top: theme.components.margin-box.medium;
	}

	> .element-layout + .element-layout.-extended {
		margin-top: theme.components.margin-box.medium;
	}
}
