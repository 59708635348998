.SearchInput {
  position: relative;

  display: flex;
  align-items: center;

  > .icon {

    box-sizing: content-box;
    width: rem(23px);
    height: rem(23px);

    z-index: 2;
    padding-left: rem(8px);
    padding-right: @padding-left;

    display: flex;
    justify-content: center;
    align-items: center;

    fill: text-color;

    &:hover {
      fill: action-color;
    }
  }

  &.-expanded > .icon {
    fill: action-color;
  }

  > .input {
    position: absolute;
    height: rem(40px);
    width: rem(200px);
    left: 0;
    z-index: 1;
    padding-left: rem(35px);
    border: solid rem(1px) #ccc;
    border-radius: rem(20px);

    &:focus {
      outline: none;
      border-color: action-color;
    }
  }

  &:not(.-expanded) > .input {
    display: none;
  }
}