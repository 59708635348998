@font-face{
    font-family:"Helvetica Regular";
    src:url("../Fonts/1488878/1b950d2a-907a-4888-8056-0d44e8e86338.eot?#iefix");
    src:url("../Fonts/1488878/1b950d2a-907a-4888-8056-0d44e8e86338.eot?#iefix") format("eot"),url("../Fonts/1488878/3a46542f-f429-4821-9a88-62e3be54a640.woff2") format("woff2"),url("../Fonts/1488878/50ac1699-f3d2-47b6-878f-67a368a17c41.woff") format("woff"),url("../Fonts/1488878/0be5590f-8353-4ef3-ada1-43ac380859f8.ttf") format("truetype");
}
@font-face{
    font-family:"Helvetica Bold";
    src:url("../Fonts/1488914/8a6c3e5a-88a5-430c-91d8-7767ce971d12.eot?#iefix");
    src:url("../Fonts/1488914/8a6c3e5a-88a5-430c-91d8-7767ce971d12.eot?#iefix") format("eot"),url("../Fonts/1488914/531c5a28-5575-4f58-96d4-a80f7b702d7b.woff2") format("woff2"),url("../Fonts/1488914/439c5962-f9fe-4eaf-a1f6-f41d42edba75.woff") format("woff"),url("../Fonts/1488914/419a308d-b777-4f84-9235-2caf4a98ec23.ttf") format("truetype");
}


html, body {
    font-family: "Helvetica Regular";
    color: text-color;
}

h1 {
    font-family: "Helvetica Bold";
    font-size: rem(48px);
    line-height: rem(58px);
}

h2 {
    font-size: rem(32px);
    line-height: rem(42px);
}

h3 {
    font-size: rem(26px);
    line-height: rem(34px);
}

p {
    font-size: rem(19px);
    line-height: rem(25px);
}

a {
    color: action-color;

    &:hover {
        color: text-color;
    }
}