/*
---
name: .chapter-element
category: LernBuch/Chapter
tag:
 - lernbuch
 - chapter
compile: true
---

## A chapter container for the lernbuch

`.chapter-element` provides styling for the chapter cover image

```jade
div.aigis-debug.chapter-element Some content
    div.aigis-debug.cover 
```

*/
.chapter-element {
    > .cover {
		margin-bottom: rem( 90px );
        background-repeat: no-repeat;
        background-size: cover;
		background-position: center center;

        height: theme.components.chapter-element.height;
    }
}