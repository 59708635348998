.SearchResult {
  top: rem(61px);
  left: 0;
  max-width: rem(850px);
  max-height: calc(100vh - 60px);
  padding: 1.25rem;
  background-color: body-color;
  border-bottom: 1px solid rgba(0,0,0,0.25);
  border-left: 1px solid rgba(0,0,0,0.25);
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.30);
  overflow-y: auto;
  position: fixed;
  z-index: 100;


  &.-hidden {
    display: none;  
  }

  > .empty.-hidden {
    display: none;  
  }

  .term-title {
    padding-right: rem(20px);
    color: action-color;
    text-transform: uppercase;
    min-height: rem( 63px );
    cursor: pointer;
  }

  .term-excerpt {

    padding-bottom: 10px;
    cursor: pointer;


    .highlight {
      background-color: rgba(66,139,202,0.4);
    }
  }

  .term-result {
    &:hover {
      color: action-color;  
    }
  }
}