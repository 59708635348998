.main-menu {

	padding: 3px 0;
	display: inline-block;
	
	&.-close {

		> .top {
			transform: translateY( 8px ) rotateZ(45deg);
		}

		> .bot {
			transform: translateY( -8px ) rotateZ(-45deg);
		}

		> .mid {
			opacity: 0;
			transform: translateX( -10px );
		}
	}

	> .line {
		height: 3px;
		width: 24px;
		background-color: currentColor;
		transition: all theme.settings.transition-duration;

		& + .line {
			margin-top: 5px;	
		}
	}
}